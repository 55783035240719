.wrapper {
    width: var(--default-layout-width);
    max-width: 100%;
    padding-inline: var(--default-layout-horizontal-spacer);
    margin: 40px auto 60px;
}
.title {
    color: var(--primary-color);
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}
.select-input {
    width: 160px;
}
@media (max-width: 480px) {
    .select-input {
        width: 100%;
    }
}
