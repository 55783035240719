@import 'normalize.css';

:root {
    --primary-color: #eb1a2d;
    --radial-gradient-bg: linear-gradient(45deg, #27be70 0%, #0c713d 100%);
    --radial-gradient-bg-2: linear-gradient(45deg, #0c713d 0%, #27be70 100%);
    --secondary-color: rgb(95, 95, 95);
    --bg-color: #080b14;
    --card-bg-color: #fff;
    --default-layout-header-height: 128px;
    --hover-color: #1f915633;
    --default-layout-width: 1320px;
    --default-layout-horizontal-spacer: 20px;
    --black: #000;
    --white: #fff;
    --text-color: #333;
    // Search
    --search-border-radius: 92px;
    --search-height: 40px;
    --search-space-height: 9px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    font-size: 62.5%;
}

html body {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.6rem;
    line-height: 1.5;
    overflow-y: overlay;
}
// Scroll bar
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}
html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
}
html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}
button,
input,
[tabindex] {
    outline: none;
    border: none;
}
a[href] {
    text-decoration: none;
}
.mt-16 {
    margin-top: 16px;
}
.d-flex {
    display: flex;
}
.jusContentCenter {
    justify-content: center;
}
.mr-16 {
    margin-right: 16px;
}
.sep {
    &::before {
        content: '';
        margin: 0 10px;
        width: 1px;
        height: 20px;
        border-radius: 50%;
        background-color: #cccccc69;
    }
}
.hovered:hover {
    cursor: pointer;
    opacity: 0.9;
}
// Grid Boostrap gutter
.g-6 {
    --bs-gutter-x: 3.5rem;
    --bs-gutter-y: 3.5rem;
}
.g-7 {
    --bs-gutter-x: 4rem;
    --bs-gutter-y: 4rem;
}
.g-8 {
    --bs-gutter-x: 4.5rem;
    --bs-gutter-y: 4.5rem;
}
.gx-6 {
    --bs-gutter-x: 3.5rem;
}
.gx-7 {
    --bs-gutter-x: 4rem;
}
.gx-8 {
    --bs-gutter-x: 4.5rem;
}
// Custom tippy tool tip
body {
    .tippy-box {
        text-align: center;
        padding: 10px;
        border-radius: 8px;
        line-height: 2rem;
        background-color: rgb(241, 241, 241);
        font-weight: 700;
        color: rgb(52, 52, 52);
        max-width: 50px;
        // box-shadow: 0px 0px 0px 10px #000;
    }
    .tippy-arrow {
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-bottom: 8px solid rgb(241, 241, 241);
    }
}
.add-item {
    animation: shakeUp 0.3s forwards ease-in-out;
}

// Keyframes
@keyframes shakeUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideToast {
    from {
        opacity: 0;
        transform: translateX(calc(100% + 32px));
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
