.wrapper {
    position: relative;
    background: url('../../assets/images/aboutus-banner.jpg') center / cover no-repeat;
    width: 100%;
    padding: 50px 10px;
    height: 280px;
    display: flex;
    align-items: center;
}
.wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000035;
}
.content {
    position: relative;
    width: 920px;
    margin: auto;
    line-height: 60px;
    padding-left: 40px;
    letter-spacing: 2px;
    position: relative;
    font-weight: 700;
    font-size: 5rem;
    color: #fff;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
.content::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: #eb1b2e;
    width: 10px;
    height: 75%;
}
