.wrapper {
    section {
        margin: auto;
    }
    li:not(:last-child) {
        margin-bottom: 5px;
    }
}
.content {
    width: var(--default-layout-width);
    max-width: 100%;
    padding-inline: var(--default-layout-horizontal-spacer);
    margin: 30px auto 60px;
}
