.wrapper {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: var(--default-layout-header-height);
    // display: flex;
    // justify-content: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.inner {
    padding-inline: var(--default-layout-horizontal-spacer);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.top-header-wrapper {
    background-color: #f9f9f9;
    border-bottom: 1px solid #e3e3e3;
    flex: 1;
}
.top-header-content {
    width: var(--default-layout-width);
    max-width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.1s ease-in-out;
}
.logo-wrapper {
    // min-width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    height: 60px;
    object-fit: cover;
}
.header-nav {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 0;
}
.header-nav_item {
    position: relative;
    text-decoration: none;
    list-style: none;
    font-weight: 600;
    font-size: x-large;
    transition: 0.1s all ease-in-out;
    color: #333;
    &:hover {
        color: var(--primary-color);
        cursor: pointer;
    }
}
.header-nav_item.active {
    color: var(--primary-color);
}
.header-nav_item + .header-nav_item::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 14px;
    background-color: #999;
}
.sub-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px 40px;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}
.sub-img {
    height: 50px;
    object-fit: contain;
}
.sub-img-wrapper {
    position: relative;
}
.sub-img-wrapper::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background-color: #999;
}
.sub-quantity {
    position: absolute;
    top: 5px;
    right: 5px;
    line-height: 1;
    font-size: 1.2rem;
    padding: 3px 5px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: var(--primary-color);
}
// Mobile things
.show-menu-mb {
    display: none;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 999;
    font-size: 4rem;
    padding: 2px 6px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #3333337b;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.menu-modal-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    z-index: 10;
}
.close-btn-mb {
    display: none;
    font-size: 3rem;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}
.search {
    display: none;
    width: 100%;
    height: 44px;
    font-size: 1.8rem;
    margin-top: 10px;
}
.sub-title {
    display: none;
}
@media (max-width: 875px) {
    .logo-wrapper {
        display: none;
    }
}
@media (max-width: 660px) {
    .wrapper {
        display: none;
        background-color: #fff;
        height: 100%;
        right: 0;
        left: auto;
        animation: slideIn 0.3s ease-in-out;
    }
    .wrapper.active {
        display: flex;
    }
    .top-header-wrapper {
        flex: none;
    }
    .top-header-content {
        flex-direction: column;
    }
    .menu-modal-overlay.active {
        display: block;
    }
    .inner {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 30px;
    }
    .logo-wrapper {
        width: 100%;
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }
    .logo {
        height: 45px;
    }
    .header-nav {
        flex-direction: column;
        justify-content: center;
        padding-top: 40px;
        align-items: center;
    }

    .header-nav_item,
    .sub-title {
        text-transform: uppercase;
        line-height: 50px;
        font-size: 2.2rem;
        font-weight: 700;
    }
    .header-nav_item + .header-nav_item {
        margin: 0;
    }
    .header-nav_item::before {
        display: none;
    }
    .sub-wrapper {
    }
    .sub-title {
        display: block;
    }

    .sub-img {
        height: 50px;
    }
    .show-menu-mb {
        display: flex;
    }
    .close-btn-mb {
        display: flex;
    }
    .search {
        display: flex;
    }
}
@keyframes slideIn {
    from {
        transform: translateX(80%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
