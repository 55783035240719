.product-img-wrapper {
    position: relative;
    height: 240px;
    width: 100%;
}
.product-img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 2px;
    box-shadow: 0px 0px 10px #00000068;
    transition: all 0.3s ease-in-out;
}

.product-img:hover {
    cursor: pointer;
}
.product-name {
    text-align: center;
    margin: 10px 0;
}
.product-price {
    position: absolute;
    right: 0;
    bottom: 0px;
    background-color: #b21500b8;
    color: #fff;
    padding: 2px 10px;
    border-top: 1px solid #ffffffab;
    font-size: 1.6rem;
}
.product-discount {
    position: absolute;
    right: 0;
    background-color: #fdcd47;
    color: #3c3e5a;
    padding: 4px 10px;
    font-weight: 600;
    border-bottom-left-radius: 10px;
}
.product-deposit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
}
