:root {
    --swiper-pagination-bottom: -20px;
    // --swiper-pagination-bullet-size: 10px;
    --swiper-theme-color: #777;
}
.wrapper {
    position: relative;
}
.swiper {
    padding: 25px 10px 25px;
    margin-top: -25px;
    position: static;
}
.slide {
    width: 100% !important;
}
.swiper-next,
.swiper-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 5px;
    font-size: 3rem;
    transition: 0.1s all ease-in-out;
    &:hover {
        cursor: pointer;
        color: var(--primary-color);
    }
    z-index: 10000;
    background-color: #dbdbdb;
    opacity: 0.7;
    line-height: 0;
}
.swiper-next:hover,
.swiper-prev:hover {
    opacity: 1;
}
.swiper-prev {
    left: 40px;
}
.swiper-next {
    right: 40px;
}
.swiper-next.disable,
.swiper-prev.disable {
    color: #ccc;
    pointer-events: none;
}
