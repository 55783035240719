.wrapper {
    background: #f5f5f5;
    width: 100%;
}
.inner {
    padding: 32px var(--default-layout-horizontal-spacer) 20px;
    margin: auto;
    width: var(--default-layout-width);
    max-width: 100%;
    color: #676767;
}
.info-wrapper {
}
.introduce {
    font-size: 1.4rem;
    margin: 20px 0;
    text-align: justify;
}
.follow-wrapper {
    h4 {
        font-weight: 700;
    }
}
.list-icons {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .social-icon {
        color: #444;
        border: 1px solid #adadad;
        width: 28px;
        height: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }
    .social-icon + .social-icon {
        margin-left: 10px;
    }
}
.info-wrapper + .info-wrapper {
    margin-top: 15px;
}
.info-title {
    font-size: 1.8rem;
    font-weight: 800;
    color: var(--primary-color);
    margin-bottom: 5px;
}
.info-subtitle {
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}
.info-subtitle + .info-subtitle {
    margin-top: 2px;
}
.info-icon {
    font-size: 2.2rem;
    margin: 5px 8px 5px 0;
    color: var(--primary-color);
}
.license-wrapper {
    border-top: 1px solid #28282820;
    padding: 10px 0;
    text-align: center;
    font-size: 1.2rem;
    color: #676767;
}
