.wrapper {
    section {
        margin: auto;
    }
    li:not(:last-child) {
        margin-bottom: 5px;
    }
}
.content {
    width: var(--default-layout-width);
    max-width: 100%;
    padding-inline: var(--default-layout-horizontal-spacer);
    margin: 30px auto 60px;
}

.estimation-wrapper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: fixed;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    max-width: 100%;
    padding: 20px 32px 10px;
}
