.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: var(--default-layout-width);
    max-width: 100%;
    padding-inline: var(--default-layout-horizontal-spacer);
    margin: 40px auto 60px;
    tbody {
        text-align: center;
        font-size: 25px;
    }
}
.product-name {
    color: var(--primary-color);
    font-weight: 700;
}
.product-image-wrapper {
    position: relative;
    .product-image {
        border-radius: 5px;
        object-fit: cover;
        transition: opacity 0.2s ease-in-out; // Hiệu ứng mờ
        opacity: 1;
    }

    &.fade {
        .product-image {
            opacity: 0.5; // Mờ dần khi chuyển đổi
        }
    }
}
